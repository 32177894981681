<template>
  <div class="application-container">
    <b-row class="mb-4">
      <b-col xl="3" lg="3" md="3" sm="12" class="mb-5">
        <b-button variant="primary" class="w-100" @click="$router.push({ name: 'PMIQuoteResult' })" v-bind:style="$store.getters.elementColourBlockBox">Return to results</b-button>
      </b-col>
      <b-col xl="9" lg="9" md="9" sm="12">
        <h4 class="mb-4" v-bind:style="$store.getters.writingColourBlockText">{{ $store.getters.applicationPolicyOwner }}, here's a summary of your cover, you can amend and update results below...</h4>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col 
          xl="4" lg="4" md="6" sm="12"
          class="mb-5"
        >
          <b-img fluid center :src="product.logo"></b-img>
        </b-col>
        <b-col xl="4" lg="4" md="6" sm="12"
          class="policy-detail-container p-8 mb-5" 
          v-bind:style="$store.getters.elementColourBlockBg"
        >
          <div>
            <h3>Your Policy Details</h3>
            <div>
              <span class="price-display">£{{ price }}</span>
              <span> per month</span>
            </div>
            <div>
              <b-row>
                <b-col cols="5"><strong>Cover for:</strong></b-col>
                <b-col cols="7">{{ $store.getters.coverFor }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="5"><strong>Cover type:</strong></b-col>
                <b-col cols="7">{{ $store.getters.applicationCoverageLabel }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="5"><strong>Excess:</strong></b-col>
                <b-col cols="7">{{ $store.getters.applicationExcessLabel }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="5"><strong>Hospital cover:</strong></b-col>
                <b-col cols="7">{{ $store.getters.applicationHospitalLabel }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="5"><strong>Chosen start date:</strong></b-col>
                <b-col cols="7">{{ $store.getters.applicationChoosenStartDate }}</b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col
          xl="4" lg="4" md="12" sm="12"
          v-if="quoteApplicationStep == 'agreement'" 
          class="policy-agreement-container p-6 mb-5"
        >
          <div class="mb-4">
            <h5>Please tick the checkbox below in order to proceed:</h5>
            <div><b-form-checkbox name="has_read_quote" v-model="hasReadQuote">Your Quote</b-form-checkbox></div>
            <div><b-form-checkbox name="confirm_no_advice" v-model="confirmNoAdvice">I confirm I have not received any advice</b-form-checkbox></div>
          </div>
          <div>
            <b-button 
              class="w-100"
              variant="primary"
              v-on:click="agreeTerms"
              :disabled="!(hasReadQuote && confirmNoAdvice)"
              v-bind:style="$store.getters.primaryButtonBox">
              <strong>{{ $store.getters.startApplicationBtnText }}</strong>
              <br v-if="$store.getters.hasSecondaryText" />
              <span v-if="$store.getters.hasSecondaryText">{{ $store.getters.startApplicationSecondaryBtnText }}</span>
              </b-button>
          </div>
        </b-col>
        <b-col 
          xl="4" lg="4" md="12" sm="12"
          v-if="quotePaymentStep == 'confirm' && quoteApplicationStep == 'applicationDetails'" 
          class="policy-agreement-container p-6 mb-5"
        >
          <div class="mb-4">
            <div><b-form-checkbox name="has_read_quote" v-model="isInformationCorrect">You confirm that all questions answered and information provided in connection with this application have been answered truthfully and honestly.</b-form-checkbox></div>    
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "start-application",
  computed: {
    product: {
      get() {
        return this.$store.state.pmi.selectedProduct;
      }
    },
    price: {
      get() {
        console.log(this.product);
        if(this.product.price){
          return this.product.selectedPrice || 0.0;
        } else {
          return 0.0;
        }
      }
    },
    hasReadQuote: {
      get() {
        return this.quoteApplication.hasReadQuote;
      },
      set(value) {
        let quoteApplication = { ...this.quoteApplication };
        quoteApplication.hasReadQuote = value;
        this.$store.commit("updateQuoteApplication", quoteApplication);
      }
    },
    isInformationCorrect: {
      get() {
        return this.quoteApplication.isInformationCorrect;
      },
      set(value) {
        let quoteApplication = { ...this.quoteApplication };
        quoteApplication.isInformationCorrect = value;
        this.$store.commit("updateQuoteApplication", quoteApplication);
      }
    },
    confirmNoAdvice: {
      get() {
        return this.quoteApplication.confirmNoAdvice
      },
      set(value) {
        let quoteApplication = { ...this.quoteApplication };
        quoteApplication.confirmNoAdvice = value;
        this.$store.commit("updateQuoteApplication", quoteApplication);
      }
    },
    quoteApplication: {
      get() {
        return this.$store.state.pmi.quoteApplication;
      }
    },
    quoteApplicationStep: {
      get() {
        return this.$store.state.pmi.quoteApplicationStep;
      }
    },
    quotePaymentStep: {
      get() {
        return this.$store.state.pmi.quotePaymentStep;
      }
    },
    coverFor: {
      get(){
        return "You";
      }
    }
  },
  methods: {
    agreeTerms: function(){
      this.$store.commit("updateApplicationStep", "applicationDetails");
      var pluginMessage = { 
        messageType: "applicationStarted",
        payload: {}
      };
      window.parent.postMessage(JSON.stringify(pluginMessage),"*");
    }
  },
  mounted: function(){
    window.parent.postMessage(JSON.stringify({messageType: "resetSize"}),"*");
  }
}
</script>

<style lang="scss">
.policy-detail-container {
  background-color: $accent;
}

.policy-agreement-container {
  background-color: $accent;
}
</style>