<template>
  <div>
    <b-alert show variant="secondary zero-radius text-center p-6">
      It's important that you answer the questions truthfully, accurately and
      completely. If you don't this may result in your policy being amended,
      cancelled or a claim not being paid.
    </b-alert>
    <client-details :askingMode="askingMode"/>
  </div>
</template>

<script>
import ClientDetails from "@/view/pages/pmi/quote-form/ClientDetails.vue"

export default {
  name: "application-details",
  components: {
    ClientDetails
  },
  data() {
    return {
      askingMode: "application"
    }
  },
  mounted() {
    this.$resizeFrame();
  }
}
</script>
